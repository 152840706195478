<template>
  <div>
    <page-header
      class="mb-3"
      :title="$t('Cookies policy')"
    />
    <b-row class="d-flex justify-content-center">
      <b-col
        xs="12"
        md="8"
      >
        <b-card>
          <b-card-text>

            <h4>1. {{ $t('Cookies policy - 1') }}</h4>
            <p>{{ $t('Cookies policy - 1 - p1') }}</p>
            <p>{{ $t('Cookies policy - 1 - p2') }}</p>
            <p>{{ $t('Cookies policy - 1 - p3') }}</p>

            <h4>2. {{ $t('Cookies policy - 2') }}</h4>
            <p>{{ $t('Cookies policy - 2 - p1') }}</p>
            <!-- eslint-disable-next-line -->
            <p v-html="$t('Cookies policy - 2 - p2')"></p>

            <table>
              <tr>
                <th>Όνομα</th>
                <th>Πηγή</th>
                <th>Διάρκεια</th>
                <th>Σκοπός</th>
              </tr>
              <tr>
                <td>laravel_session</td>
                <td>womenontop.gr</td>
                <td>{{ $t('1 hour') }}</td>
                <td>{{ $t('Used to identify user\'s session') }}</td>
              </tr>
              <tr>
                <td>XSRF-TOKEN</td>
                <td>womenontop.gr</td>
                <td>{{ $t('1 hour') }}</td>
                <td>{{ $t('Used for protection from XSS attacks') }}</td>
              </tr>
            </table>

            <!-- eslint-disable-next-line -->
            <p v-html="$t('Cookies policy - 2 - p3')"></p>

            <table>
              <tr>
                <th>Όνομα</th>
                <th>Πηγή</th>
                <th>Διάρκεια</th>
                <th>Σκοπός</th>
              </tr>
              <tr>
                <td>_ga</td>
                <td>Google</td>
                <td>{{ $t('2 years') }}</td>
                <td>{{ $t('Used to distinguish unique users.') }}</td>
              </tr>
              <tr>
                <td>_gid</td>
                <td>Google</td>
                <td>{{ $t('1 day') }}</td>
                <td>{{ $t('Used to distinguish unique users.') }}</td>
              </tr>
              <tr>
                <td>_gat</td>
                <td>Google</td>
                <td>session</td>
                <td>{{ $t('Used to throttle the request rate to the server.') }}</td>
              </tr>
              <tr>
                <td>OTZ</td>
                <td>Google</td>
                <td>{{ $t('2 years') }}</td>
                <td>{{ $t('Used to compile aggregate statistics.') }}</td>
              </tr>
              <tr>
                <td>OGP</td>
                <td>Google</td>
                <td>{{ $t('2 years') }}</td>
                <td>{{ $t('Used to connect the user on Google Maps.') }}</td>
              </tr>
            </table>

            <!-- eslint-disable-next-line -->
            <p v-html="$t('Cookies policy - 2 - p4')"></p>
            <!-- eslint-disable-next-line -->
            <p v-html="$t('Cookies policy - 2 - p5')"></p>

            <h4>3. {{ $t('Cookies policy - 3') }}</h4>
            <p>{{ $t('Cookies policy - 3 - p1') }}</p>
            <p>{{ $t('Cookies policy - 3 - p2') }}</p>
            <p>{{ $t('Cookies policy - 3 - p3') }}</p>
            <!-- eslint-disable-next-line -->
            <p v-html="$t('Cookies policy - 3 - p4')"></p>

          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BCol,
  BRow
} from 'bootstrap-vue'
import PageHeader from '@mentoring-platform/views/components/blocks/PageHeader.vue'

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BRow,
    PageHeader
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>
p {
  line-height: 1.8rem;
}

table, td, th {
  border: 1px solid black;
}

table {
  margin-bottom: 20px;
  width: 100%;
}

td, th {
  padding: 1px 4px;
}
</style>
