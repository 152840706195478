<template>
  <div class="col-sm-12 d-flex flex-column align-items-center text-center my-5">
    <h1 class="font-weight-bolder">
      <slot name="title">
        {{ title }}
      </slot>
    </h1>
    <div class="col-sm-12 col-md-6" v-if="!hideMessage">
      <slot name="message">
        {{ message }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    message: {
      type: String
    },
    hideMessage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    };
  },
};
</script>

<style scoped>

</style>
